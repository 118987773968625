import { useSelector } from 'react-redux'

function useAuth() {
    
    const user = useSelector((state) => state.auth.user)

  
    return {
        authenticated: Object.keys(user).length > 0,
    }
}

export default useAuth
