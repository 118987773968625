import { AUTH_PREFIX_PATH } from 'constants/route.constant'

function clearLocalStorage(requestType) {
    // Remove all other localstorage which is related to go to signin page, so that after login it dont go to any other page.
    localStorage.removeItem('authentication_required_for_product_report')

    localStorage.removeItem('authentication_required')

    // This is for routing after the login.
    if (requestType === 'reportsPageRequest') {
        localStorage.removeItem('purchasesPageRequest')
    } else if (requestType === 'purchasesPageRequest') {
        localStorage.removeItem('reportsPageRequest')
    }
}

// let lang="en"
export function getAppConfig(lang) {
    // this is for if we have some parentRef in the url and we will get that.

    function getLastPartOfUrl() {
        let refValue = ''

        // Example URL: domain.com?ref=asdfasd
        const queryParams = new URLSearchParams(window.location.search)
        if (queryParams.has('ref')) {
            refValue = queryParams.get('ref')
            const prefix = 'a_aid='

            if (refValue.startsWith(prefix)) {
                refValue = refValue
                return refValue
            } else {
                refValue = 'a_aid=' + refValue
                return refValue
            }
        }
        // Example URL: domain.com?a_aid=asdfasd
        else if (queryParams.has('a_aid')) {
            refValue = queryParams.get('a_aid')
            refValue = 'a_aid=' + refValue
            return refValue
        }

        // Example URL: domain.com#ref=asdfasd
        const hashParams = new URLSearchParams(
            window.location.hash.substring(1)
        )
        if (hashParams.has('ref')) {
            refValue = hashParams.get('ref')
            const prefix = 'a_aid='

            if (refValue.startsWith(prefix)) {
                refValue = refValue
                return refValue
            } else {
                refValue = 'a_aid=' + refValue
                return refValue
            }
        }
        // Example URL: domain.com#aid=asdfasd
        else if (hashParams.has('a_aid')) {
            refValue = hashParams.get('a_aid')
            const prefix = 'a_aid='

            if (refValue.startsWith(prefix)) {
                refValue = refValue
                return refValue
            } else {
                refValue = 'a_aid=' + refValue
                return refValue
            }
        }

        // Example URL: domain.com/ref/asdfasd
        const pathSegments = window.location.pathname.split('/')
        if (
            pathSegments.length >= 2 &&
            pathSegments[pathSegments.length - 2] === 'ref'
        ) {
            refValue = pathSegments[pathSegments.length - 1]
            const prefix = 'a_aid='

            if (refValue.startsWith(prefix)) {
                refValue = refValue
                return refValue
            } else {
                refValue = 'a_aid=' + refValue
                return refValue
            }
        }

        // http://localhost:3000/en/home/#a_aid=OuWPLl
        const currentUrl = window.location.href
        const url = new URL(currentUrl)
        const params = new URLSearchParams(url.hash.slice(1)) // Remove the '#' character

        if (params.has('a_aid')) {
            const affiliateId = params.get('a_aid')
            // localStorage.setItem('parentRef',`a_aid=${affiliateId}`);
            refValue = `a_aid=${affiliateId}`
        }

        return refValue
    }
    let parentRef = getLastPartOfUrl()
    if (parentRef && process.env.REACT_APP_FORCE_REF_SIGNUP === 'TRUE') {
        localStorage.setItem('parentRef', parentRef)
    }

    // these query params are for if we move directly from email link to reports or purchases page.
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)

    let loggedInOnce = localStorage.getItem('loggedInOnce')
    const authentication_required = localStorage.getItem(
        'authentication_required'
    )
    const authenticationRequiredForProductReportDownload = localStorage.getItem(
        'authentication_required_for_product_report'
    )
    // This is for routing after the login.
    let isReportsPageRequest = localStorage.getItem('reportsPageRequest')
    let isPurchasesPageRequest = localStorage.getItem('purchasesPageRequest')

    const reportsPageRequest = urlParams.get('reportsPageRequest')
    const purchasesPageRequest = urlParams.get('purchasesPageRequest')
    if (reportsPageRequest) {
        // isReportsPageRequest = true
        if (!localStorage.getItem('reportsPageRequest')) {
            localStorage.setItem('reportsPageRequest', true)
            clearLocalStorage('reportsPageRequest')
        }
    } else if (purchasesPageRequest) {
        // isPurchasesPageRequest = true
        if (!localStorage.getItem('purchasesPageRequest')) {
            localStorage.setItem('purchasesPageRequest', true)
            clearLocalStorage('purchasesPageRequest')
        }
    }

    let language = lang || 'en'

    const appConfig = {
        apiPrefix: '/api',
        // authenticatedEntryPath: '/home',
        // the authentication_required is for if user come on the signin page from buy product then we wil redirect user back to report-address page from where it come.
        authenticatedEntryPath: authentication_required
            ? `/${language}/report-address`
            : isPurchasesPageRequest
            ? `/${language}/my-account/purchases`
            : isReportsPageRequest
            ? `/${language}/my-account/reports`
            : authenticationRequiredForProductReportDownload
            ? `/${language}/report`
            : `/${language}/home`,

        // unAuthenticatedEntryPath: `${AUTH_PREFIX_PATH}/${language}/account`, // Add the language parameter
        unAuthenticatedEntryPath:
            (purchasesPageRequest || reportsPageRequest) && loggedInOnce
                ? `${AUTH_PREFIX_PATH}/${language}/account`
                : (purchasesPageRequest || reportsPageRequest) && !loggedInOnce
                ? `${AUTH_PREFIX_PATH}/${language}/register`
                : `/${language}/home`,
        tourPath: '/',
        locale: 'en',
        enableMock: false,
    }
    return appConfig
}

// export default appConfig
