import React, { useEffect } from 'react'

import Theme from 'components/template/Theme'
import Layout from 'components/layout'
import { useDispatch, useSelector } from 'react-redux'
import useLanguageClass from 'utils/hooks/useLanguageClass'
import { App } from 'realm-web'
import { runClientDebug } from 'utils/runClientDebug'
import { useNavigate } from 'react-router-dom'

// import mockServer from './mock'
import './locales'
// import runOneSignal from 'utils/runOneSignal'
import { init } from 'communication_engagement'
import { setAppInstance } from 'store/appInstance/appSlice'
import { userLoggedOut } from 'store/auth/userSlice'

// walletconnect imports

/**
 * Set enableMock(Default false) to true at configs/app.config.js
 * If you wish to enable mock api
 */
// if (environment !== 'production' && appConfig.enableMock) {
//     mockServer({ environment })

// console.log("")
// }

function MyApp() {
    // This is for debugging, this is how we can only enable console in dev mode and not display them in prod mode
    if (
        process.env.NODE_ENV === process.env.REACT_APP_ENV_DEVELOPMENT ||
        process.env.REACT_APP_ENV === process.env.REACT_APP_ENV_DEVELOPMENT
    ) {
        window.clientdebug = true
    }
  

    // for test
    // use below function when ever you want to use console, and pass console value in params.
    runClientDebug('dev_mode')
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const lang = useSelector((state) => state.locale.currentLang)

    const app = new App({ id: process.env.REACT_APP_APP_ID })

    app?.currentUser?.refreshCustomData()

    useEffect(() => {
        if (!app?.currentUser) {
            dispatch(userLoggedOut())
            navigate(`/auth/${lang}/account`)
        }
    }, [])

    window.app = app

    useEffect(() => {
        // Here we are storing this appInstance into redux store
        dispatch(setAppInstance(app))
    }, [])

    // Initializing OneSignal SDK
    useEffect(() => {
        async function runOneSignal() {
            await init()
        }
        runOneSignal()
    }, [])

    // this is because when we reload the page then this component is rerender, so on reload we need to set the lang class to body
    useLanguageClass(lang)
    // this effect is for if the you move on our app from some url in which the language is ar but your current language is en, so we will
    // update the url and add en as user local selected language.
    useEffect(() => {
        let newLang = lang

        // Extract base path and target route from currentUrl
        const urlParts = window.location.href.split('/')

        const basePath = urlParts[1] // Assuming second part is the base path (adjust as needed)
        const target = urlParts[2] // Assuming third part is the target route (adjust as needed)
        let langPart
        // Build new URL using template literals
        let newUrl
        // this is for auth urls like sigin etc
        if (urlParts[3] === 'auth') {
            newUrl =
                '/' + urlParts[3] + '/' + lang + '/' + urlParts[5]
                    ? urlParts[5]
                    : 'home'

            langPart = urlParts[4]
        }
        // this is for normal urls
        else {
            // if user come for some ref url, there we dont have lang in url, so dont do any thing in that case.
            if (urlParts[3] === 'ref') {
                // do nothing
                return
            } else {
                newUrl = '/' + lang + '/' + urlParts[4] ? urlParts[4] : 'home'
                langPart = urlParts[3]
            }
        }

        if (langPart !== lang) {
            navigate(newUrl)
        }
    }, [])

    // Adding a comment
    return (
        <div className="!bg-white">
            <Theme>
                <Layout />
            </Theme>
        </div>
    )
}

export default MyApp
