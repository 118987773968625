import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    products: [],
}

export const basketProductsSlice = createSlice({
    name: 'basket/products',
    initialState,
    reducers: {
        addProduct: (state, action) => {
            return { ...state, products: [...state.products, action.payload] }
        },
        updateProduct: (state, action) => {
            const updatedProducts = state.products.map((product) => {
               
                if (
                    product.id === action.payload.id &&
                    product.metadata.address.value ===
                        action.payload.metadata.address.value
                ) {
                    return action.payload
                }
                return product
            })
            return { ...state, products: updatedProducts }
        },
        removeAllProductsWithSameID: (state, action) => {
            const filteredProducts = state.products.filter(
                (product) => product.id !== action.payload
            )
            return { ...state, products: filteredProducts }
        },
        removeSingleProductOnceOfSameID: (state, action) => {
            let removed = false

            const updatedProducts = state.products.filter((product) => {
                if (!removed && product.id === action.payload) {
                    removed = true
                    return false // Exclude the first occurrence
                }
                return true
            })

            return { ...state, products: updatedProducts }
        },
        removeAllProducts: (state) => {
            return { ...state, products: [] }
        },
        setProducts: (state, action) => {
            return { ...state, products: action.payload }
        },
    },
})

export const {
    addProduct,
    updateProduct,
    removeAllProductsWithSameID,
    removeSingleProductOnceOfSameID,
    removeAllProducts,
    setProducts,
} = basketProductsSlice.actions
export default basketProductsSlice.reducer
